import { default as indexPA1jtQSyzaMeta } from "/builds/mis/medflex_landing/pages/index.vue?macro=true";
import { default as mis0WrQfGgWkgMeta } from "/builds/mis/medflex_landing/pages/mis.vue?macro=true";
export default [
  {
    name: indexPA1jtQSyzaMeta?.name ?? "index",
    path: indexPA1jtQSyzaMeta?.path ?? "/",
    meta: indexPA1jtQSyzaMeta || {},
    alias: indexPA1jtQSyzaMeta?.alias || [],
    redirect: indexPA1jtQSyzaMeta?.redirect,
    component: () => import("/builds/mis/medflex_landing/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mis0WrQfGgWkgMeta?.name ?? "mis",
    path: mis0WrQfGgWkgMeta?.path ?? "/mis",
    meta: mis0WrQfGgWkgMeta || {},
    alias: mis0WrQfGgWkgMeta?.alias || [],
    redirect: mis0WrQfGgWkgMeta?.redirect,
    component: () => import("/builds/mis/medflex_landing/pages/mis.vue").then(m => m.default || m)
  }
]