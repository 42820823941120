import { createVuetify } from 'vuetify'
import { ru } from 'vuetify/locale'

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    ssr: true,
    theme: {
      themes: {
        light: {
          colors: {
            primary: '#1A5DD0',
            'primary-darken-1': '#3700B3',
            secondary: '#FF4A37',
            'secondary-darken-1': '#018786',
            info: '#1A5DD0',
            success: '#1EB37C',
            error: '#FF4A37',
            warning: '#FFA928',
            anchor: '#1A5DD0',
            'ui-kit-brand': '#3981F1',
            'ui-kit-illustration': '#3981F1',
            'ui-kit-special': '#904FE2',
            'ui-kit-rating': '#FFA928',
            'ui-kit-button-text-snackbars': '#76CBFF',
            'ui-kit-text': '#111111',
            'ui-kit-text-secondary': '#424B58',
            'ui-kit-text-info': '#607A93',
            'ui-kit-text-success': '#00815A',
            'ui-kit-text-error': '#F52A14',
            'ui-kit-text-warning': '#DF5000',
            'ui-kit-disabled': '#8DA2BA',
            'ui-kit-icon-primary': '#111111',
            'ui-kit-icon-secondary': '#607A93',
            'ui-kit-bg-primary': '#ECF1FB',
            'ui-kit-bg-secondary': '#F6F9FF',
            'ui-kit-bg-special': '#F0E4F5',
            'ui-kit-bg-success': '#E0F6EE',
            'ui-kit-bg-error': '#FCE8E8',
            'ui-kit-bg-warning': '#FFF2D9',
            'ui-kit-bg-club': '#FFEEE5',
            'ui-kit-bg-gray-80': '#8DA2BA',
            'ui-kit-bg-gray-60': '#C7D1DF',
            'ui-kit-bg-gray-40': '#F3F6FA',
            'ui-kit-bg-gray-0': '#FFFFFF'
          }
        }
      }
    },
    icons: {
      aliases: {
        checkboxOff: 'ui-icon ui-icon-checkbox-off',
        checkboxOn: 'ui-icon ui-icon-checkbox-on',
        radioOff: 'ui-icon ui-icon-radio-off',
        radioOn: 'ui-icon ui-icon-radio-on',
        dropdown: 'ui-icon ui-icon-arrow-down',
        delete: 'ui-icon ui-icon-close-not-a-circle',
        clear: 'ui-icon ui-icon-close-not-a-circle',
        next: 'ui-icon ui-icon-arrow-right',
        ratingFull: 'ui-icon ui-icon-rating-star-filled',
        ratingEmpty: 'ui-icon ui-icon-rating-star-outline',
        ratingHalf: 'ui-icon ui-icon-rating-star-half',
        prev: 'ui-icon ui-icon-arrow-left',
        complete: 'ui-icon ui-icon-check',
        error: 'ui-icon ui-icon-circle-warning',
        warning: 'ui-icon ui-icon-circle-info',
        expand: 'ui-icon ui-icon-arrow-down',
        close: 'ui-icon ui-icon-close-not-a-circle',
        info: 'ui-icon ui-icon-circle-info',
        checkboxIndeterminate: 'ui-icon ui-icon-indeterminate',
        success: 'ui-icon ui-icon-check-circle'
      }
    },
    locale: {
      locale: 'ru',
      messages: { ru }
    },
    defaults: {
      VAvatar: {
        rounded: 'circle'
      },
      VSpacer: {
        class: 'flex-grow-1'
      },
      VTextField: {
        color: 'primary',
        variant: 'outlined'
      },
      VChip: {
        color: 'primary',
        variant: 'outlined'
      },
      VChipGroup: {
        color: 'primary',
        variant: 'outlined'
      },
      VAutocomplete: {
        variant: 'outlined',
        menuProps: {
          scrollStrategy: 'block',
          maxWidth: '100%',
          contentClass: 'base-select-menu-content'
        }
      },
      VSlider: {
        color: 'primary'
      },
      VTabs: {
        color: 'primary'
      },
      VSelect: {
        variant: 'outlined',
        menuProps: {
          scrollStrategy: 'block',
          maxWidth: '100%',
          contentClass: 'base-select-menu-content'
        }
      },
      VBanner: {
        color: 'primary'
      },
      VBtn: {
        color: 'primary',
        variant: 'flat'
      },
      VNavigationDrawer: {
        order: 0,
        location: 'right',
        tag: 'div'
      },
      VAppBar: {
        order: 1
      },
      VFooter: {
        order: 1
      },
      VToolbar: {
        VBtn: {
          color: null
        },
        height: 56,
        color: 'white'
      },
      VTextarea: {
        color: 'primary',
        variant: 'outlined'
      },
      VExpansionPanel: {
        collapseIcon: 'ui-icon-arrow-up'
      },
      VCheckbox: {
        color: 'primary'
      }
    }
  })

  app.vueApp.use(vuetify)

  return {
    provide: {
      vuetify
    }
  }
})
