/**
 * Производит редирект всех страниц (кроме главной) без trailingSlash на страницы с trailingSlash с 301 статусом редиректа.
 * Важно для SEO.
 * */
export default defineNuxtRouteMiddleware((to) => {
  if (to.path !== '/' && !to.path.endsWith('/')) {
    const { path, query, hash } = to
    const nextPath = path + '/'
    const nextRoute = { path: nextPath, query, hash }

    return navigateTo(nextRoute, {
      redirectCode: 301
    })
  }
})
