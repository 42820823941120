import revive_payload_client_4sVQNw7RlN from "/builds/mis/medflex_landing/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/mis/medflex_landing/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/mis/medflex_landing/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/mis/medflex_landing/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/mis/medflex_landing/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/builds/mis/medflex_landing/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/mis/medflex_landing/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_6oy0ulSF66 from "/builds/mis/medflex_landing/node_modules/nuxt-csurf/dist/runtime/plugin.mjs";
import vuetify_plugin_u5jU6XzrZj from "/builds/mis/medflex_landing/modules/vuetify/runtime/plugin/vuetify.plugin.ts";
import plugin_t2GMTTFnMT from "/builds/mis/medflex_landing/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/mis/medflex_landing/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import index_FI80Z3fnmz from "/builds/mis/medflex_landing/plugins/mask/index.ts";
import mail_ru_metrika_client_OZHoGrvQqX from "/builds/mis/medflex_landing/plugins/mail-ru-metrika.client.ts";
import yandex_metrika_client_MiNHrQhx1f from "/builds/mis/medflex_landing/plugins/yandex-metrika.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_6oy0ulSF66,
  vuetify_plugin_u5jU6XzrZj,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  index_FI80Z3fnmz,
  mail_ru_metrika_client_OZHoGrvQqX,
  yandex_metrika_client_MiNHrQhx1f
]